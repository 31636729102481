/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "NinetyNineAnalytics" }]*/

import trackEvent from '@99designs/common/utils/platform/trackEvent';

/**
 * Wrapper for Segment API that allows sending a set of default properties
 * with event payloads, and adds engagement (scrolldepth / activity tracking)
 */

var NinetyNineAnalytics = (function ($, riveted) {
    var initializeActivityTracking = function () {
        riveted.init({
            eventHandler: function (activeTime) {
                if (activeTime === 30 || activeTime % 60 == 0) {
                    trackEvent('Engaged On Blog Post', { active_time_in_seconds: activeTime });
                }
            }.bind(this),
        });
    };

    var initializeScrollDepthTracking = function () {
        $(document).ready(
            function () {
                $.scrollDepth({
                    percentage: true,
                    userTiming: false,
                    pixelDepth: false,
                    nonInteraction: false,
                    eventHandler: function (data) {
                        trackEvent('Scrolled Blog Post To ' + data.eventLabel, {
                            page_height: $(document).height(),
                        });
                    }.bind(this),
                });
            }.bind(this)
        );
    };

    return {
        track: function (name, payload = {}) {
            trackEvent(name, payload);
        },

        trackEngagement: function () {
            initializeActivityTracking.bind(this)();
            initializeScrollDepthTracking.bind(this)();
        },
    };
})(jQuery, riveted);

window.NinetyNineAnalytics = NinetyNineAnalytics;
